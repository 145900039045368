<template>
  <div id="app">
    <div class="container-fluid">
      <nav
        class="navbar fixed-top"
        style="background-color: #1f2122; justify-content: left"
      >
        <img
          src="./assets/curtis_emblem_white.svg"
          width="75px"
          style="margin-top: -5px"
        />
        <h1
          style="
            font-family: 'Anton', sans-serif;
            color: white;
            font-size: 48px;
          "
        >
          JOSH CURTIS
        </h1>
        <h2
          style="
            font-family: 'Anton', sans-serif;
            color: #888888;
            padding-left: 20px;
          "
        >
          CODER, FLY-FISHER, MUSICIAN, MOTORCYCLIST
        </h2>
      </nav>
      <div class="row" style="padding-top: 125px">
        <div class="col-md-4">
          <b-img-lazy
            v-for="image in images1"
            :key="image"
            :src="image"
            fluid
            class="thumbnail"
          />
        </div>
        <div class="col-md-4">
          <b-img-lazy
            v-for="image in images2"
            :key="image"
            :src="image"
            fluid
            class="thumbnail"
          />
        </div>
        <div class="col-md-4">
          <b-img-lazy
            v-for="image in images3"
            :key="image"
            :src="image"
            fluid
            class="thumbnail"
          />
        </div>
      </div>
      <div class="row" style="padding-bottom: 25px; padding-left: 20px">
        <a href="https://www.linkedin.com/in/josh-c-62024a81/">
          <img
            src="./assets/li.png"
            height="45px"
            style="padding-right: 10px"
          />
        </a>
        <a href="https://www.instagram.com/cowboycurtis__/">
          <img src="./assets/ig.png" width="45px" height="45px" />
        </a>
      </div>
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #d1d9e0;
  background-color: #1f2122;
}

.thumbnail {
  width: 100%;
  padding: 0px;
  border: 0px solid #4d4e62;
}

a {
  text-decoration: none;
}
</style>

<script>
export default {
  name: "GalleryImageProviders",
  computed: {
    images1() {
      const path = require.context("@/assets/gallery", false, /.*$/);
      // return path.keys().map(path)
      return path
        .keys()
        .slice(0, path.keys().length / 3)
        .map(path);
    },
    images2() {
      const path = require.context("@/assets/gallery", false, /.*$/);
      // return path.keys().map(path)
      return path
        .keys()
        .slice(
          path.keys().length / 3,
          path.keys().length / 3 + path.keys().length / 3
        )
        .map(path);
    },
    images3() {
      const path = require.context("@/assets/gallery", false, /.*$/);
      // return path.keys().map(path)
      return path
        .keys()
        .slice((path.keys().length / 3) * 2, (path.keys().length / 3) * 3)
        .map(path);
    },
  },
};
</script>
